import React, { Component } from 'react';
import CaseStudyTitle from '../Components/CaseStudyTitle';
import ParagraphBorderLeft from '../Components/ParagraphBorderLeft';
import caseStudyCover from '../Assets/img/webbuilder/webbuilder-cover.png';
import builderFinished from '../Assets/img/webbuilder/builder-finished.png';
import experiments from '../Assets/img/webbuilder/Experiments.png';
import oldwb from '../Assets/img/webbuilder/old-wb.png';
import wbMindmap from '../Assets/img/webbuilder/wb-mindmap.png';
import wireframes from '../Assets/img/webbuilder/Wireframes.jpg';
import maze from '../Assets/img/webbuilder/Maze.png';
import { motion } from "framer-motion";

class webBuilder extends Component {
    render() {
        return (
            <motion.div 
                initial={{ opacity: 0, y:-50 }}
                animate={{ y:0 }}
                whileInView={{ opacity: 1 }}
                transition={{duration:.75, ease: "easeOut" }}
            >
            <div class="case-study">
                <CaseStudyTitle title="Improving a website builder" role="Product Designer" />
                <div class="container">
                    <div class="case-study-hero img-shadow" style={{backgroundImage: "url(" + caseStudyCover + ")"}}>
                    </div>
                </div>
                
                <div className="container-short">
                    <ParagraphBorderLeft content="Popmenu is a restaurant technology company focused on providing restaurateurs with an online presence that includes a website, dynamic menu, online ordering, and more. " />

                    <p>When operating a restaurant, one of the most important aspects (besides great tasting food, of course!) is marketing and putting their name out there. In the past, many have relied heavily on word of mouth, some not having a website or online presence. But these days, having a website is one of the most important things a restaurant could have, especially during the pandemic when many resorted to takeout and pickup. Most of us will look at a restaurant’s website to peruse their menu, what their vibe is, as well as important information like events, parking, reservations, and so on. </p>


                    <h3 class="mt-10">The Problem</h3>

                    <p>Popmenu was launching a new product called Indie where clients could self-onboard and create their own website without any assistance from our client services team. For this project, there were two parts: the self-onboarding experience and a new web builder. My team took on the web builder part of the project. </p>

                    <p>The Indie clients were classified as mom & pop or new restaurants that needed something simple, like a one-page website with online ordering, to get them started in one sitting.</p>

                    <p>Being able to edit their website is something that they should be able to do easily. However, Popmenu’s current page editor was far from user-friendly and needed to be updated to allow clients to create a website themselves.</p>

                    <h4>My role</h4>
                    <p>I was the main product designer on this project and worked on the project from discovery to release with my product manager and a team of engineers.</p>

                    <h3 class="mt-10">Interviewing clients and internal employees</h3>
                    
                    <p>My product manager and I started interviewing current clients about their experience with updating the content on their websites. Our goal was to understand their behaviors and feelings around the process. In addition to Indie clients, we also needed to take into account our current clients (which is a range of small restaurants to large multi-location restaurants). Both types of clients would have the same experience.</p>

                    <p>The reception to our current builder was poorly received. Clients were terrified of doing something wrong and then not knowing how to fix it. Here were the main patterns that we saw:
                        <ul>
                            <li>The UI was very complicated</li>
                            <li>They felt like they needed to reach out to support for small changes on their site like changing photos or adding another section</li>
                            <li>While they may be able to modify existing pages, creating a new page would be overwhelming to them.</li>
                            <li>When it came to time-sensitive changes, like making a change for an upcoming holiday, clients would become frustrated since support could take a while to get back to them. This would also make them lose revenue if a change wasn't completed in a timely manner</li>
                            <li>They disliked not being able to see the changes they make while they make it</li>
                        </ul>

                    </p>

                    <p>Most of our clients didn't have a lot of time during the day to sit at the computer and edit their website, nor did they have time or patience to wait for a response from support. They were usually juggling many different tasks at once, such as resolving conflicts, cleaning the tables, taking orders, and so on. Larger clients would have marketing staff that would manage all aspects of the website and social media, but this was rare.</p>

                    <p>We needed an experience that would be simple enough to be able to perform a task within a few minutes.</p>
                    <p>Below are screenshots of the old web builder.</p>

                </div>

                <div className="container-short">
                    <img className="img-shadow" src={oldwb} width="100%" />
                </div>

                <div class="container-short">

                    <p>
                        We also interviewed our internal teammates on their experience. We reached out to customer service, support, and the web design team since they were using it the most. Their feedback was similar to our clients, with many voicing wanting to see the changes as they made it, like how Squarespace and other popular web builders handled it. 
                    </p>

                    <p>To edit content, they would have two tabs open: one with the page editor, and the other with the website. This was cumbersome to deal with, and even worse, any changes they made to the website would automatically be published – there was no draft mode. </p>


                    <h3 class="mt-10">Exploration</h3>

                    <p>We mapped out the experience from onboarding to publishing their website with a flow chart to first understand the user journey.</p>

                </div>
                <div className="container">
                    <img className="img-shadow" src={wbMindmap} width="100%" />
                </div>

                <div class="container-short">

                    <p>We started exploring some designs around the layout, taking inspiration from popular web builders so as not to re-invent the wheel. We wanted to use patterns that most would be familiar with. Providing users a preview is the standard in web builders, so this was essential to include. In this iteration, something like inline editing wouldn’t be possible in the timeframe we were given, so we decided to place the content editing in a sidebar. </p>
                </div>
                
                <div className="container">
                    <img className="img-shadow" src={wireframes} width="100%" />
                </div>
                <div class="container-short">

                    <p>I created some clickable prototypes and we tested the designs internally and  with clients and received positive feedback. Based on feedback, we would reiterate the designs and then test the new designs. We went through cycles of monitored usability testing on Zoom calls and using Maze.io testing, making small tweaks based on the trending feedback. Below are some examples grabbed from a Maze test we ran.</p>

                </div>

                <div class="container">
                    <img className="img-shadow" src={maze} width="100%" />
                </div>

                <div class="container-short">

                    <h3 class="mt-10">Results</h3>

                    <p>We started rolling out the finished product to our internal web design team for them to catch any bugs and give us some more feedback around the usability. Once they felt comfortable with the improvements we made, we slowly rolled out to beta clients in chunks so as not to overwhelm our customer support team with calls and tickets about the web builder. This also allowed us to process client-reported bugs before it became available to all clients.</p>

                    <p>More clients reported feeling more comfortable using the web builder now that they can see all the changes they make. Having a draft mode to let them edit their site until they are ready to publish was also a huge plus for them. Our internal website designers were delighted about the change, since they now could see all of their changes as they made them instead of flipping back and forth between tabs. </p>

                    <p>We rolled it out to the Indie experience as well and saw that there were clients successfully creating a website themselves. However, the company decided to pivot and phase out the Indie product. Even if Indie didn't succeed, it at least prompted us to improve the web builder experience for all clients.</p>

                </div>

                <div className="container">
                    <img className="img-shadow" src={builderFinished} width="100%" />
                    <div className="spacer"></div>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-sm-6">
                        </div>
                        <div className="col-sm-6">
                            <div className="text-right">
                                <a href="/case-study/maive">Maive →</a>
                            </div>
                        </div>
                    </div>
                
                <br /><br />
                </div>

                

            </div>
            </motion.div>
        );
    }
}

export default webBuilder;