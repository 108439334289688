import React, { Component } from "react";
import portrait from "../Assets/img/kim.png";
import graphics from "../Assets/img/graphics.png";

class Hero extends Component {
  render() {
    return (
      <div className="hero">
        <div className="container">
          <section className="row">
            <div className="col-sm-5">
              <h1>kimberly bicknese</h1>
              <h4 className="font-bold">
                Product designer, oil painter, traveler, & collector of random
                hobbies
              </h4>
              <p>
                I create end-to-end solutions that help empower people to
                navigate the digital world. One of my many hobbies include
                dabbling in code - this portfolio was made with ReactJS!
              </p>
              <a href="mailto:synthoxide@gmail.com" class="btn-default">
                Get in touch
              </a>
            </div>
            <div className="col-sm-7 text-center">
              <img src={portrait} />
              <img src={graphics} className="float-img" />
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default Hero;
