import React from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Navigation from './Components/Navigation';
import Footer from './Components/Footer';
import './style.css';
import HomeTest from './Pages/Home2';
import About from './Pages/About';
import HomeSearch from './Pages/consumer-homesearch';
import HousesNetApp from './Pages/housesnetapp';
import Gadiver from './Pages/georgiaaquarium';
import CincWebsite from './Pages/cinc-website';
import MassEmail from './Pages/massemail';
import Maive from './Pages/maive';
import WebBuilder from './Pages/Webbuilder';
import { motion } from "framer-motion";



const App = () => {
  return (
      <Router>
        <Navigation />
        <Routes>
          <Route exact path="/" element={<HomeTest />} />
              <Route path="/about" element={<About />} />
              <Route path ="/case-study/home-search" element={<HomeSearch />} />
              <Route path ="/case-study/housesnet-app" element={<HousesNetApp />} />
              <Route path ="/case-study/georgia-aquarium" element={<Gadiver />} />
              <Route path ="/case-study/cinc-website" element={<CincWebsite />} />
              <Route path ="/case-study/mass-message-wizard" element={<MassEmail />} />
              <Route path ="/case-study/maive" element={<Maive />} />
              <Route path ="/case-study/webbuilder" element={<WebBuilder />} />
              <Route render={() => <h1>Page not found</h1>} />
          </Routes>
          <Footer />
      </Router>
    
  );
};

export default App;
