import React, { Component } from "react";

class CaseStudyPreviewHz extends Component {
  render() {
    return (
      <a href={this.props.caseStudyURL} className="case-study-url">
        <div className="case-study-preview-hz">
          <div class="row">
            <div
              class="col-md-7 case-study-preview-hz-img"
              style={{ backgroundImage: "url(" + this.props.thumbimg + ")" }}
            ></div>
            <div class="col-md-5 content">
              <h4>{this.props.title}</h4>
              <p>{this.props.desc}</p>
              <p>
                <button className="btn-default">Read More</button>
              </p>
            </div>
          </div>
        </div>
      </a>
    );
  }
}

export default CaseStudyPreviewHz;
