import React, { Component } from 'react';
import CaseStudyTitle from '../Components/CaseStudyTitle';
import ParagraphBorderLeft from '../Components/ParagraphBorderLeft'
import caseStudy1 from '../Assets/img/georgiaaquarium/ga-dive-cover.jpg';
import gadive1 from '../Assets/img/georgiaaquarium/gadive-dashboard.jpg';
import gadive2 from '../Assets/img/georgiaaquarium/gadive-flow.jpg';
import gadive3 from '../Assets/img/georgiaaquarium/gadive-logs.jpg';
import gadive4 from '../Assets/img/georgiaaquarium/gadive-training-staff.jpg';
import gadive5 from '../Assets/img/georgiaaquarium/gadive-training.jpg';
import { motion } from "framer-motion";


class gaAquarium extends Component {
    render() {
        return (
            <motion.div 
                initial={{ opacity: 0, y:-50 }}
                animate={{ y:0 }}
                whileInView={{ opacity: 1 }}
                transition={{duration:.75, ease: "easeOut" }}
            >
            <div class="case-study">
                <CaseStudyTitle title="Georgia Aquarium Dive Ops Portal" role="UX/UI Designer" />
                <div class="container">
                    <div class="case-study-hero img-shadow" style={{backgroundImage: "url(" + caseStudy1 + ")"}}>
                    </div>
                </div>
                
                <div className="container-short">
                    <ParagraphBorderLeft content="Georga Aquarium's DiveOps portal is a place where volunteer and staff scuba divers can access their dive logs and check if their training is up to date." />

                    <h3>How do we make training easier?</h3>
                    
                    <p>One of the issues that the DiveOps portal was facing that it was an outdated UI, was difficult to use, and it was difficult to check on your training status or sign up for training.</p>

                    <p>The staff had to manually send out emails to individual volunteer divers, alerting them that their training (such as First Aid or a Medical Checkup) was expiring. This was tedious and took precious man hours away from actually caring for the facilities and animals. This project was a way to create accountability. They wanted volunteers to check in on their training status themselves through the portal and also, in the future, be able to sign up for classes and training.</p>

                    <h4>My role</h4>
                    <p>This project was volunteer work completed during my free time. I worked with the DiveOps Manager and three volunteer engineers, creating information architecture, wireframes, and clickable prototypes. At this time, because it's difficult to find volunteers who can set aside time for this project, it still has not been implemented yet. </p>

                    <h3>Prototyping</h3>

                    
                    <p>Because the current dashboard navigation was convoluted and difficult to navigate on the Staff view, I first started out on how we wanted to organize the pages. Collaborating with the engineers and asking the DiveOps manager what pages were needed, I was able to prune out old and outdated pages or merge them together. </p>

                    <img className="img-shadow" src={gadive2} width="100%" />
                    <div className="spacer"></div>
                    <p>The dashboard for the volunteer view would show important information. For most volunteer divers, we need to complete 24 dive shifts per year to stay in the dive program. The dashboard would show how many dives they have completed during their time at the aquarium, how many more dives they need this year, their training so far (and what's about to expire), events, and important phone numbers in case the diver is late or unable to fulfill their shift.</p>
                    <div className="spacer"></div>
                </div>
                <div className="container">
                    <img className="img-shadow" src={gadive1} width="100%" />
                    <div className="spacer"></div>
                </div>
                <div className="container-short">
                    
                    <p>In addition, the portal would allow divers to track their dive logs in case they wanted to collect these dives for future certifications or just as record keeping. All fields are disabled because volunteers don’t have the ability to edit their log after their dive. Only staff are able to edit them.</p>
                    <div className="spacer"></div>
                </div>
                <div className="container">
                    <img className="img-shadow" src={gadive3} width="100%" />
                    <div className="spacer"></div>
                </div>
                
                <div className="container-short">

                    
                    <p>The purpose of this redesign was also to rethink how we can make signing up for training at the aquarium easier. There was limited functionality on creating and managing training. I envisioned a page with all of the training classes available that divers could freely schedule at their own convenience rather than contacting the DiveOps Manager for available dates. </p>

                    <p>You could view your training history, current classes that are available, and the classes that you have registered for.</p>
                    <div className="spacer"></div>

                </div>
                <div className="container">
                    <img className="img-shadow" src={gadive5} width="100%" />
                    <div className="spacer"></div>
                </div>

                <div className="container-short">
                    <p>In the staff view of the training courses, staff would be able to add and remove classes and see how many divers have signed up for the class. </p>
                    <div className="spacer"></div>
                </div>

                <div className="container">
                <img className="img-shadow" src={gadive4} width="100%" />
                <div className="spacer"></div>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="text-left">
                                <a href="/case-study/mass-message-wizard">← A mass message wizard</a>
                            </div>
                        </div>
                        {/* <div className="col-sm-6">
                            <div className="text-right">
                                <a href="/case-study/cinc-website">Redesigning CINC's website →</a>
                            </div>
                        </div> */}
                    </div>
                
                <br /><br />
                </div>
                

            </div>
            </motion.div>
        );
    }
}

export default gaAquarium;