import React, { Component } from 'react';
import CaseStudyTitle from '../Components/CaseStudyTitle';
import Timeline from '../Components/Timeline';
import ParagraphBorderLeft from '../Components/ParagraphBorderLeft'
import caseStudy1 from '../Assets/img/consumerhomesearch/consumersearch.jpg';
import oldExpVideo from '../Assets/video/old-search-walkthrough.mov';
import searchFinal from '../Assets/img/consumerhomesearch/search-final.jpg';
import searchFinalLive from '../Assets/img/consumerhomesearch/search-final.png';
import searchFiltersFinal from '../Assets/img/consumerhomesearch/searchfilters-desktop-final.jpg';
import propdetailsFinal from '../Assets/img/consumerhomesearch/propdetails-final.jpg';
import searchMobile1 from '../Assets/img/consumerhomesearch/search-mobile-final.jpg';
import searchMobile2 from '../Assets/img/consumerhomesearch/searchmap-final.jpg';
import searchMobile3 from '../Assets/img/consumerhomesearch/searchfilter-final.jpg';
import propdetailsMobile1 from '../Assets/img/consumerhomesearch/propdetails-mobile-final.jpg';
import propdetailsMobile2 from '../Assets/img/consumerhomesearch/propdetails-mobile-final2.jpg';
import propdetailsMobile3 from '../Assets/img/consumerhomesearch/propdetails-mobile-final3.jpg';
import oldFilters from '../Assets/img/consumerhomesearch/old-filters.png';
import searchSketch from '../Assets/img/consumerhomesearch/search-sketches.png';
import filterIdeas from '../Assets/img/consumerhomesearch/search-filter-ideas.png';
import { motion } from "framer-motion";


class SearchCaseStudy extends Component {
    render() {
        return (
            <motion.div 
                initial={{ opacity: 0, y:-50 }}
                animate={{ y:0 }}
                whileInView={{ opacity: 1 }}
                transition={{duration:.75, ease: "easeOut" }}
            >
            <div class="case-study">
                <CaseStudyTitle title="Redesigning a home search experience" role="UX/UI Designer, UI Developer"/>
                <div class="container">
                <div class="case-study-hero img-shadow" style={{backgroundImage: "url(" + caseStudy1 + ")"}}>
                </div>
                </div>
                <div className="container-short">
                    <ParagraphBorderLeft content="CINC is a real-estate lead generation platform that provides real-estate agents with a robust CRM dashboard to manage their leads and business. CINC also provides them with their own consumer home search experience that is the driver in capturing leads." />

                    <p>Buying a home is one of the biggest investments you'll make in your life. The process of finding a home is not the easiest - it can be frustrating, adrenaline-pumping, exciting, and terrifying all at once. There's so many options, looking at all the properties and keeping track of what you like and dislike can be exhausting!</p>
                    
                    <p>And our sites weren't helping out. By analyzing video recorded through <a href="http://fullstory.com" target="_blank">Fullstory</a>, home buyers stumbled using our sites because the filters were downright confusing and overwhelming.</p>

                    <p>The goal of this project was, how do we increase conversion on our sites and provide a pleasant experience for home buyers?</p>

                    <video width="100%" controls>
                        <source src={oldExpVideo} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>

                    <h4>My Role</h4>

                    <p>I contributed to the redesign of the consumer experience and collaborated with my product manager and a team of engineers.</p>

                    <p>I owned the design process from start to finish, from ideation and research to making my designs come to life in code using VueJS, HTML, & SASS.</p>
                    
                    
                </div>

                

                <div className="container-short">

                    <h3 className="mt-10">So what do users want?</h3>
                    <p>
                        My team and I went out to conduct user interviews to first figure out how home buyers searched for a home. We observed them while they used popular home search sites like Zillow and Trulia, noting the steps they took to filter down their results and what they focused on the most in the home. We also had them use our current sites to see where their pain points were - most of them disliked it compared to other sites.
                    </p>

                    <p>One of the biggest pain points users had with our current sites was filtering. It was choice paralysis. </p> 

                <p>They also had issues in finding all of the data on a property in one place, such as nearby amenities, schools, crime data, demographics, and general MLS data. They had to go to multiple sites to piece together all the information they needed and would make Word docs or spreadsheets for each home to keep track of it.</p>

                

                </div>

                <div className="container">
                    <img src={oldFilters} width="100%" />
                </div>

                <div className="container-short">
                    <h3 className="mt-10">To the drawing board</h3>
                    
                    <p>
                        With this my team set up shop in Starbucks and asked the cafe guests to test our prototype. It was the fastest way for our team to gather insights quickly. These sessions lasted about 10-20 minutes, depending how much time the person could spare for us.
                    </p>
                </div>

                <div className="container text-center">
                    <img src={searchSketch} width="80%" />
                </div>

                <div className="container-short">

                    

                    <p> As our prototypes became more refined, we started doing some testing through <a href="http://usertesting.com">Usertesting.com</a> and arranging interviews with users from Craigslist ads.</p>

                </div>

                <div className="container-short">

                    <p>Some hurdles we went through was how to make filters easy to find. Should some of the important filters, like price, bed, and bath, be exposed? Or should they all be hidden under a "Filters" drawer?

                    </p>

                    
                </div>

                <div className="container">
                <img src={filterIdeas} width="100%" />
                </div>

                
                <div className="container-short">
                    <p>Eventually, we decided to place all the filters under a "Filters" drawer to simplify the experience. In our intial round of usability testing with our prototypes, users were able to quickly find where to adjust their filters, and so we believed that this was the best route for us to take.</p>

                    <h3 className="mt-10">Aha!</h3>
                    <p>
                    We concluded that having the listings on the left while a map was on the right, tested the best and was smoothest experience for our users. </p>

                </div>

                <div className="container img-container">
                    <div className="row">
                        <div className="col-sm-6">
                            <img className="img-shadow" src={searchFinal} width="100%" />
                        </div>
                        <div className="col-sm-6">
                            <img className="img-shadow" src={searchFiltersFinal} width="100%" />
                        </div>
                    </div>
                </div>
                <div className="container-short">
                    <p>
                    It satisfied both the user that relied more heavily on using the map and the user who just wanted to see photos.</p>
                    
                    <p>We simplified the filters panel, providing only a small subset of the major filters our users cared about — like beds, baths, price, sqft, year built, etc — and then dumping the miscellaneous, rarely used filters into an ‘advanced filters’ accordion. Later Google Analytics statistics would show that people rarely clicked on the advanced filters.
                    </p>

                    <p>For mobile, users wanted to see the list of properties first and be able to switch to the map if needed. The filters would be arranged in the same fashion on desktop.</p>

                    <div className="row">
                        <div className="col-sm-4">
                            <img className="img-shadow" src={searchMobile1} width="100%" />
                        </div>
                        <div className="col-sm-4">
                            <img className="img-shadow" src={searchMobile2} width="100%" />
                        </div>
                        <div className="col-sm-4">
                            <img className="img-shadow"src={searchMobile3} width="100%" />
                        </div>
                    </div>

                    <p>On the property details page, we created a large image carousel that would showcase the home in all of its glory, since photos were the most important thing that home buyers wanted to see first. If users didn't like how the photos looked of a home, they would simply move on to the next.</p>

                </div>
                <div className="container img-container">
                    <img className="img-shadow" src={propdetailsFinal} width="100%" />
                    
                </div>
                <div className="container-short">   
                <div className="row">
                        <div className="col-sm-4">
                            <img className="img-shadow" src={propdetailsMobile1} width="100%" />
                        </div>
                        <div className="col-sm-4">
                            <img className="img-shadow" src={propdetailsMobile2} width="100%" />
                        </div>
                        <div className="col-sm-4">
                            <img className="img-shadow" src={propdetailsMobile3} width="100%" />
                        </div>
                    </div>
                    <p> We also added extra features that did not exist on the old framework, including a mortgage calculator, nearby amenities that were plotted on a map, and more in-depth information on schools based on what we heard from the majority of users.</p>
                </div>

                <div className="container-short">
                    <h3 className="mt-10">Time to build it!</h3>
                    <p>Now came the big part — actually building it. Because my team did not have a front-end developer, I subbed in and built a custom HTML/SASS framework from the ground up with their guidance. We considered using common frameworks like Bootstrap to speed up the process, but by using a pre-existing framework, things would get messy really quick with all the override classes we would have to create to make the site our own and flexible enough for our needs.</p>

<p>I honestly LOVED this part of the process. I always coded websites as a hobby, so to actually flex those muscles on this project was very exciting for me. I wrote all of the pages we were re-designing with HTML/SASS/basic JS and worked closely with the developers to wire everything up, ensuring the site was responsive across all devices as well. We managed stories through Jira and tracked progress on each story from start to finish.</p>

<p>Throughout this process, development encountered many snags in the codebase that kept pushing our timeline further back. This was a huge learning process for my team and I; we were the first team to rewrite an existing product from scratch, so we were learning as we went.</p>

<h4>Further testing</h4>
    
    <p>We had a working site that we released to a handful of beta-user clients. Now that it was pulling live data from the MLS, my team set out again to test the usability through Usertesting.com and our favorite tactic of pulling random people from Starbucks to test the site. We also conducted A/B tests through <a href="http://optimizely.com" target="_blank">Optimizely</a> and <a href="http://vwo.com" target="_blank">VWO</a> to see if we could tweak the experience further. Through this, we found some more changes we could make to ensure the experience more smooth, such as providing better indicators when something was loading, smoother map interactions, enhancements on mobile, and tweaking how the filters were displayed.</p>

                
                </div>

                <img src={searchFinalLive} width="100%" />
                <div className="container-short">
                    <h3 className="mt-10">The results</h3>
                    <p>
                    We slowly rolled out the redesigned search and property detail pages to our 2,000+ client websites. 
                    </p>

                    <p>Our users also responded well to it —  we saw a 40% increase in conversion across all sites, which meant that more users were registering and becoming leads because of the redesign. That was a massive accomplishment for us. Not only did we increase conversion on our sites, but we also built a more scalable product where adding new features or MLS compliance overrides are less of a headache than they once were, allowing us to make changes quicker and delight our clients and users.</p>
                    <br /><br />
                </div>
                
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="text-left">
                            <a href="/case-study/maive">← Maive</a>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="text-right">
                                <a href="/case-study/housesnet-app"> houses.net mobile app →</a>
                            </div>
                        </div>
                    </div>
                
                <br /><br />
                </div>
            </div>
            </motion.div>
        );
    }
}

export default SearchCaseStudy;