import React, { Component } from 'react';
import logo from '../logo.svg';
import { NavLink } from 'react-router-dom';
import Resume from '../Assets/kimbicknese-resume.pdf';


class Navigation extends Component {
    render() {
        return (
            <div className="header">
                <div className="container">
                  <div className="row middle-xs">
                    <div className="col-sm-12">
                      <Nav />
                    </div>
                  </div>
                </div>
            </div>
        );
    }
}

function Logo(props) {
  return (
    <div className="header-logo">
      <img src={logo} alt="" height="40" />
    </div>
  );
}

const routes = [
  { to: '/', label: 'Work' },
  { to: '/about', label: 'About' },
];

const Nav = () => {
  const links = routes.map(({ to, label }) => {
    return <NavLink strict exact to={to} key={to}><li>{label}</li></NavLink>}
  )

  return (
    <div className="header-nav">
        { links }
    </div>
  );
}

export default Navigation;