import React, { Component } from 'react';

class CaseStudyTitle extends Component {
    render () {
        return (
            <div className="case-study-title">
                <div className="container">
                    <div className="row middle-sm">
                        <div className="col-sm-9">
                            <h2>{this.props.title}</h2>
                        </div>
                        <div className="col-sm-3">
                            <h6 className="margin-0">ROLE</h6>
                            {this.props.role}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CaseStudyTitle;