import React, { Component } from 'react';
import Hero from '../Components/Hero';
import CaseStudyPreviewHz from '../Components/CaseStudyPreviewHz';
import caseStudy1 from '../Assets/img/consumerhomesearch/consumersearch.jpg';
import caseStudy2 from '../Assets/img/housesnetapp/housenetmockup-screens.jpg';
import caseStudy3 from '../Assets/img/georgiaaquarium/ga-dive-cover.jpg';
import caseStudy4 from '../Assets/img/cinc-website/cinc-hero.jpg';
import caseStudy5 from '../Assets/img/massemail/mass-message-cover.jpg';
import caseStudy6 from '../Assets/img/maive/maive-hero.jpg';
import caseStudy7 from '../Assets/img/webbuilder/webbuilder-cover.png';
import shelterManagement from "../Assets/img/design-snippets/sheltermanagement-comp.jpg";
import petJournal from "../Assets/img/design-snippets/petjournal.png";
import leadsRedesign from "../Assets/img/design-snippets/leads-redesign.jpg";
import { motion } from "framer-motion";

class HomeTest extends Component {
    render() {
        return (
            <motion.div 
                initial={{ opacity: 0, y:-50 }}
                animate={{ y:0 }}
                whileInView={{ opacity: 1 }}
                transition={{duration:.75 }}
            >
                <Hero />
                <div class="container">
                    <section>
                        <h4 className="vertical">Projects</h4>
                        <div className="row">
                            <div className="col-sm-12">
                                <CaseStudyPreviewHz title="Improving a website builder" desc="Revamping the web builder to deliver an enhanced and streamlined web-building experience for both clients and internal teams." role="Product Designer" caseStudyURL="/case-study/webbuilder" thumbimg={caseStudy7}/>
                            </div>
                            <div className="col-sm-12">
                                <CaseStudyPreviewHz title="Maive" desc="Maive was a startup aspiring to create a marketplace where AI models can be shared between developers" role="UX/UI Designer, Graphic Designer, UI Developer" caseStudyURL="/case-study/maive" thumbimg={caseStudy6}/>
                            </div>
                            <div className="col-sm-12">
                                <CaseStudyPreviewHz title="Redesigning a home search experience" desc="In this case study I walk through the journey of leading a redesign of a home search experience." role="UX/UI Designer, UI Developer" caseStudyURL="/case-study/home-search" thumbimg={caseStudy1}/>
                            </div>
                            <div className="col-sm-12">
                                <CaseStudyPreviewHz title="houses.net mobile app" desc="Crafting a national home search app that fostered collaboration between home seekers and real estate agents." role="UX/UI Designer" caseStudyURL="/case-study/housesnet-app" thumbimg={caseStudy2}/>
                            </div>
                            <div className="col-sm-12">
                                <CaseStudyPreviewHz title="A mass message wizard" desc="The email compose component wasn't working for mass emails. We needed to create a mass email feature that would accomodate to sending emails to thousands of leads." role="UX/UI Designer" caseStudyURL="/case-study/mass-message-wizard" thumbimg={caseStudy5}/>
                            </div>
                            <div className="col-sm-12">
                                <CaseStudyPreviewHz title="Georgia Aquarium DiveOps Portal" desc="Georgia Aquarium wanted to redo their DiveOps portal, where divers managed their dive training and dive logs." role="UX/UI Designer" caseStudyURL="/case-study/georgia-aquarium" thumbimg={caseStudy3}/>
                            </div>
                            {/* <div className="col-sm-6">
                                <CaseStudyPreview title="Redesigning CINC's website" desc="CINC was wanting to rebrand their logo and redesign their website." role="UX/UI Designer, UI Designer" caseStudyURL="/case-study/cinc-website" thumbimg={caseStudy4}/>
                            </div> */}
                        </div>
                    </section>
                    
                    <section>
                        <h4 className="vertical">Design Snippets</h4>

                        <div className="lead green short">
                            I like to tinker and experiment. Here’s a collection of some design experiments done for fun.
                        </div>

                        <div className="spacer"></div>

                        <div className="row middle-sm">
                    
                            <div className="col-md-7">
                                <div className="article">
                                    <div className="article-number">01</div>
                                    <h4>Pet Health Tracker</h4>
                                    <p>My dog, Simon, is a senior-aged pup with mobility issues due to degenerative myelopathy. Every week, he goes to physical therapy and it is imperative to track how the treatments are helping him.</p>
                                    
                                    <p> One day I felt like it would be great to have an app that tracked a pet's health every day with three easy indicators: happy face, neutral face, and sad face. This could tell you how your pet is doing throughout the week, visually show good and bad days, and also allow for time-stamped observations. A pet parent could easily flip this app open during a vet consultation to explain how their pet is doing.</p>
                                </div>
                            </div>
                            <div className="col-md-5 text-center">
                                <img src={petJournal} width="80%"/>
                            </div>
                        </div>

                        <div className="spacer"></div>

                        <div className="row middle-sm">
                            <div className="col-md-7">
                                <img src={shelterManagement} width="100%" className="img-shadow" />
                            </div>
                            <div className="col-md-5">
                                <div className="article">
                                <div className="article-number">02</div>
                                    <h4>Shelter Management</h4>
                                    <p>This was a fun project imagining a more intuitive shelter management software. Volunteering at my local animal shelter, I've seen the software they currently use to keep track of pets at the shelter. It's clunky, hard to use, and nothing but tables from the 90s.</p>

                                    <p>Adoption rates are critical for shelters to track their progress. This graph would be front and center. Urgent pets were those that needed to be out of the shelter ASAP. Other supplemental information such as fosters, adoption requests, and so on would surround these data points.</p>
                                </div>
                            </div>
                        </div>

                        <div className="spacer"></div>

                        

                        <div className="row middle-sm">
                            <div className="col-md-5">
                                <div className="article">
                                <div className="article-number">03</div>
                                    <h4>Leads Dashboard Redesign</h4>
                                    <p>At CINC, the design team was tasked with coming up with ideas of how the leads dashboard, which contains important information about a home buyer, could be redesigned and reimagined. It was also an exercise as a design team to try to figure out what sort of style and patterns we'd want so we could start creating a design system.</p>

                                    <p>We envisioned making it visually cleaner and minimal so it would be easier to digest the table of information. Because these were just ideas, they haven't been tested or placed in front of users yet.</p>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <img src={leadsRedesign} width="100%" className="img-shadow" />
                            </div>
                            
                        </div>

                    </section>
                    

                </div>
                
            </motion.div>
        );
    }
}

export default HomeTest;