import React, { Component } from 'react';
import CaseStudyTitle from '../Components/CaseStudyTitle';
import caseStudy1 from '../Assets/img/massemail/mass-message-cover.jpg';
import massmsg1 from '../Assets/img/massemail/mass-message-flow-1.png';
import massmsg2 from '../Assets/img/massemail/mass-message-flow-2.png';
import massmsg3 from '../Assets/img/massemail/mass-message-flow-final.png';
import { motion } from "framer-motion";


class massEmail extends Component {
    render() {
        return (
            <motion.div 
                initial={{ opacity: 0, y:-50 }}
                animate={{ y:0 }}
                whileInView={{ opacity: 1 }}
                transition={{duration:.75, ease: "easeOut" }}
            >
            <div class="case-study">
                <CaseStudyTitle title="A mass message wizard" role="UX/UI Designer"  />
                <div class="container">
                    <div class="case-study-hero img-shadow" style={{backgroundImage: "url(" + caseStudy1 + ")"}}>
                    </div>
                </div>
                
                <div className="container-short">
                    <h3 className="mt-10">A spammy mess</h3>

                    <p>Being able to contact a lead is the very thing that our clients - real estate agents - rely on to grow their business. Our current email compose modal wasn’t helping. It was confusing and frustrating to use.</p>

                    <p>Clients would unknowingly send emails to thousands of leads, resulting in their emails being marked as spam. There wasn’t the ability to schedule emails. There was no easy way to segment leads and see who the leads in a group they were sending to. And clients weren’t able to preview what an email looked like while they were editing it, which led to some funky-looking emails if I’m being totally honest. </p>

                    <p>Our agents wanted to be able to easily send out mass emails to their leads, whether it be tips on buying or selling a home, open houses, and holiday events and free pie giveaways during Thanksgiving. But they were unable to do so with our clunky interface. 
                    </p>

                    <h3 className="mt-10">What do clients need?</h3>

                    <p>We sat down with our clients to ask them what improvements we could make on the email compose modal and what their pain points were. </p>

                    <p>They expressed frustration with not being able to easily choose templates and preview how the email would look as they edited them. Many of our clients are not savvy with HTML, so they have no idea how to create their own HTML emails. It would be great, most of them said, if we could provide them with some basic templates that would make their emails look more professional and easy to look at.</p>

                    <p>Email analytics were a must and something we were currently lacking. How well was their email performing? How many clicks or subscribers were there? What could they be doing better so that their open and click-through rates increased? </p>

                    <p>And how could they schedule their emails to send on a date sometime in the future? No one wants to log in on Christmas day to send out a “Merry Christmas” email to their clients when they want to spend time with their family instead - could we provide a way for them to schedule this on Christmas day? </p>

                    <p>How could they target a group of leads based on filters? What if they only want to target first-time home buyers, or sellers, or those that are looking to buy in a month or less?</p>

                    <p>And while they’re sending a mass email, could they also send the same message through a text message? We have the ability to send out mass texts, but they were in separate areas of the platform.</p>

                    <p>Through these conversations, we started to piece together a flow of the mass message engine which basically looked like this:
                    </p>
                </div>

                <div className="container">
                    <img src={massmsg1} width="100%" />
                </div>

                <div className="container-short">
                    


                    <p>Because there were so many steps, it made sense to break it up into smaller, easily digestible steps to help guide them through the process. We decided it would be best to walk our clients through a step-by-step wizard.
                    </p>


                    <h3 className="mt-10">Designing a multi-step email wizard</h3>

                    <p>With all of this information at hand, it was time to create some prototypes. My design colleague who works extensively on the CRM side of the platform had already created a UX pattern for a wizard, template chooser, and email scheduler so I collaborated with her to make sure everything was consistent. </p>

                    <p>My first thought was to have our clients choose the template first so they knew the type of message they wanted to send before anything else. If they knew what type of email they were sending first, they could then choose their leads based on that. I created clickable prototypes in Invision and presented the first flow:</p>
                </div>
                <div className="container">
                    <img src={massmsg2} width="100%" />
                </div>

                <div className="container-short">

                    <p>But clients actually didn’t like that flow. They preferred to be able to choose their target leads first so they knew what type of template and message to craft. </p>

                    <p>We introduced a new term “segment” to describe a group of similar leads, such as “First-time Home Buyers”. However, 4 out of 5 clients were confused as to what that term meant. So instead of the term "segment", we settled with "smart list", which resonated better with our clients.</p>

                    <p>Clients wanted to be able to save their most-used smart lists for later so they wouldn’t have to recreate it every time. My thought was to allow them to build it in the main view and have a button to save the smart list down below as well as a sidebar dropdown that stored it.</p>

                    <p>However, clients started to muse that for the smart lists, they would like to see a list of all the leads that were in that list. </p>

                    <p>After several more iterations and interviews with our clients, we settled on this final flow:</p>

                </div>

                <div className="container">
                    <img src={massmsg3} width="100%" />
                </div>

                <div className="container-short">

                    <p>We also changed the smart list to show the list of leads instead of the filter properties so they could have a visual of what leads they were sending to. They didn’t want to spam a lead with emails if that lead vocalized that they didn’t like emails. So with this, they would be able to remove leads if they believed the lead shouldn’t receive that email based on past conversations with them. 
                    </p>

                    <h3 className="mt-10">Implementation</h3>

                    <p>We did several more rounds of testing until we were confident that the solution was in front of us and ready to be implemented in code.</p>

                    <p>My designs were handed off to the engineers to build. My design colleague and I helped the engineers with questions on UI implementation such as padding, spacing, colors, etc. My role with this project ended and my colleague took over as I was pulled into another project. 
                    </p>
            
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="text-left">
                                <a href="/case-study/housesnet-app">← houses.net mobile app</a>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="text-right">
                                <a href="/case-study/georgia-aquarium">Georgia Aquarium Dive Ops Portal →</a>
                            </div>
                        </div>
                    </div>
                
                <br /><br />
                </div>
                

            </div>
            </motion.div>
        );
    }
}

export default massEmail;