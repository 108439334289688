import React, { Component } from 'react';
import CaseStudyTitle from '../Components/CaseStudyTitle';
import maiveHero from '../Assets/img/maive/maive-hero.jpg';
import maiveLogoCreation from '../Assets/img/maive/maive-logo-creation.png';
import maiveHomePage from '../Assets/img/maive/maive-homepage.png';
import maiveCompetitors from '../Assets/img/maive/maive-competitors.jpg';
import maiveWireframes from '../Assets/img/maive/maive-wireframes.png';
import maiveWeb from '../Assets/img/maive/maive-web-prototypes.png';
import maiveMobile from '../Assets/img/maive/maive-mobile-prototypes.png';
import Timeline from '../Components/Timeline';
import ParagraphBorderLeft from '../Components/ParagraphBorderLeft';
import { motion } from "framer-motion";

class maive extends Component {
    render() {
        return (
            <motion.div 
                initial={{ opacity: 0, y:-50 }}
                animate={{ y:0 }}
                whileInView={{ opacity: 1 }}
                transition={{duration:.75, ease: "easeOut" }}
            >
            <div class="case-study">
                <CaseStudyTitle title="Maive" role="UX/UI Designer, Graphic Designer, UI Developer" />
                <div class="container">
                    <div class="case-study-hero img-shadow" style={{backgroundImage: "url(" + maiveHero + ")"}}>
                    </div>
                </div>
                <div class="container-short">
                    <ParagraphBorderLeft content="Maive was an AI-based marketplace idea where developers could share their open-source AI models that could be utilized in other software projects." />

                    <p>AI is rapidly growing, from the Nest thermostat to the powerful AI self-driving capabilities in Tesla cars. It’s already starting to become ingrained in our daily lives. How can we create an easy to use marketplace on the web and mobile app for developers to host their AI models for others to utilize?</p>

                    <h3 class="mt-10">Building a new product with limited funds</h3>

                    <p>This was my first project where there was a limited budget to work with, so ultimately very little budget or time for user research. In the plan was a desktop web application that consisted of a marketplace and a backend where users could upload, manage, and sell their AI models. A mobile app for iOS and Android was also in the works, with the CEO already having built the barebones foundation.</p>

                    <p>Our main meter for success was how many users signed up in the alpha phase. Building a user base and interest would mean we are on the right path. </p>

                    <h3 class="mt-10">Branding</h3>

                    <p>I started off by creating the branding for Maive including the logo, color scheme, and fonts and kept iterating based on the feedback of the CEO until we reached the final design. </p>
                </div>
                <div class="container">
                    <img src={maiveLogoCreation} width="100%"/>
                </div>

                <div class="container-short mt-10">
                    <h3>Updating the homepage</h3>
                    <p>Prior to hiring me, the CEO had created a placeholder landing page for the website and needed a new design that communicated the story of his mission. Now that we had a logo, brand colors, and branding feel, I set out to make the homepage more engaging and informative. </p>
                </div>

                <div class="container">
                    <img src={maiveHomePage} width="100%"/>
                </div> 

                <div class="container-short mt-10">
                    <h3>Creating a marketplace</h3>
                    <p>With limited funds, we didn’t really have any resources for user testing and research. Competitive analysis was the next best thing for scoping out what other companies in our same space were doing. </p>


                        <img src={maiveCompetitors} width="100%"/>

                    <p>
                    With the analysis, we created the bare-bones requirements for the MVP:
                        <ul>
                            <li>A marketplace (mobile & desktop) where users can buy and sell AI models</li>
                            <li>A profile for both the buyer and seller (mobile & desktop)</li>
                            <li>A page for sellers to upload their models (desktop only)</li>
                            <li>All models would be free for now until we found users to use our product</li>
                        </ul>
                    </p>
                </div>

                <div class="container">
                    <img src={maiveWireframes} width="100%"/>
                </div>

                <div class="container-short">

                    <p>On the web experience, users would be able to peruse the marketplace, preview how models worked by uploading an image, file, video, etc to see results in JSON, and download AI models to their account. They could upload their files and submit their model to the marketplace within minutes.</p>

                    <p>For the mobile app, the experience was similar to the web marketplace, except that users weren’t able to upload or manage their models from the app. AI models created for mobile devices, such as VR or AR experiences that required the use of a camera, could be downloaded to the app and used like an app on your phone. </p>

                    <p>Wireframes were presented to the CEO, and after those were tweaked and in a good place, I began work on a prototype for both the web and mobile app. </p>
                </div>

                <div class="container">

                    <img src={maiveWeb} width="100%"/>

                    <img src={maiveMobile} width="100%"/>
                </div>

                <div class="container-short">

                    <p>Once I was finished with the prototype, I started translating that into HTML/CSS and React models, as the foundation of the React app was already up and running. We wasted no time in getting something bare-bones working so that we could send it to peers, obtain learnings on how others perceived the idea, and hopefully continue iterating on the project once we got more of that feedback.</p>

                    <p>We tested the prototypes with coworkers and friends who were developers and found positive impressions. We got to a point where some of it was working, such as the upload piece and the mobile app marketplace. </p>

                    <p>However, after a few months in the making, the project was ultimately scrapped due to funding before we could really hunker down and get it in front of more users.

                    While this product didn't succeed, it was a learning experience for both of us and helped us grow more in our fields.  
                        </p>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="text-left">
                            <a href="/case-study/webbuilder">← Improving a web builder</a>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="text-right">
                                <a href="/case-study/home-search">Redesigning a home search experience →</a>
                            </div>
                        </div>
                    </div>
                
                <br /><br />
                </div>

            </div>
            </motion.div>
            );
        }
    }
    
export default maive;

