import React, { Component } from 'react';
import { motion } from "framer-motion";
import kimphotos from '../Assets/img/bio.png';
import Resume from '../Assets/kimbicknese-resume.pdf';


class About extends Component {
    render() {
        return (
            <motion.div 
                initial={{ opacity: 0, y:-50 }}
                animate={{ y:0 }}
                whileInView={{ opacity: 1 }}
                transition={{duration:.75, ease: "easeOut" }}
            >
            <div className="container-short">
                <h2>Hi, I'm Kim!</h2>
                
                <p>I'm a product designer and artist based in Atlanta, GA with a passion for creating delightful experiences. Since I was young, I've had a love for design and fine arts. I always dreamed of having some sort of creative career, so it's exciting that I can help users navigate the digital world through the experiences I craft.</p>

                <p>In addition to product design, I love exploring the world of code every now and then. I first learned the wonders of HTML/CSS back in the days of Myspace and it morphed into me building websites as a hobby. I'm proud to say that this portfolio was created with ReactJS. Updating this certainly keeps me on my toes with code!</p>

                <p>Outside of the office, I enjoy bouncing between my many hobbies which include oil painting, rock climbing, hiking, muay thai, photography, scuba diving, and traveling the world with my husband. In 2022, I decided to put more effort into my oil paintings and have participated in several fine art shows around Atlanta. If you want to learn more about my art, <a href="https://kimbickneseart.com" target="_blank">feel free to head over to my portfolio.</a> </p>

                <p>Interested in learning more about my experience? <a href={Resume} target="_blank">Here's my resume.</a></p>
            </div>

            <div class="container">
                <br />
                <img className="img-shadow" src={kimphotos} width="100%" />
                <br />
            </div>
            </motion.div>
        );
    }
}

export default About;