import React, { Component } from 'react';


class Footer extends Component {
    render() {
        return (
            <div className="footer">
                <div className="container">
                  <div className="row middle-xs">
                    <div className="col-sm-6">
                      <p className="lead">
                          Have a project or just want to say hi? <br />
                          Get in touch and let's make something awesome!
                      </p>
                    </div>
                    <div className="col-sm-6 footer-links">
                       <li><a href="https://www.linkedin.com/in/kimberly-bicknese/" target="_blank">LinkedIn</a></li> 
                       <li><a href="http://instagram.com/kimbickneseart" target="_blank">Art Instagram</a></li> 
                       <li><a href="mailto:synthoxide@gmail.com" target="_blank">Email</a></li>
                        
                    </div>
                  </div>
                </div>
                <a href="#"><span className="back-to-top"></span></a>
            </div>
        );
    }
}

export default Footer