import React, { Component } from 'react';
import CaseStudyTitle from '../Components/CaseStudyTitle';
import cincHero from '../Assets/img/cinc-website/cinc-hero.jpg';
import cincMockup1 from '../Assets/img/cinc-website/cinc-mockup1.png';
import cincLogo from '../Assets/img/cinc-website/cinc_logo_horizontal.png';
import cincFinal from '../Assets/img/cinc-website/cinc-final.png';


class cincWebsite extends Component {
    render() {
        return (
            <div class="case-study">
                <CaseStudyTitle title="Redesigning CINC's Website" role="UX/UI Designer, UI Developer"/>
                <div class="container">
                    <div class="case-study-hero img-shadow" style={{backgroundImage: "url(" + cincHero + ")"}}>
                    </div>
                </div>
                
                <div className="container-short">
                    <br /><br />

                    <h3>The Challenge</h3>
                    
                    <p>CINC wanted to update their existing website to better tell their story as a company. The current website was outdated and missing a lot of information about new features and products they had since released. </p>

                    <p>How can we create a visually strong and informative website that guided the user through the product and clearly communicated the benefits of signing up with CINC?</p>

                    <h4>My Role</h4>

                    <p>I collaborated with our Marketing team and the design team. I assisted in creating wireframes, clickable mockups, and implementing the mockups into code through Wordpress.</p>

                    <h3>The All-In-One Elite Solution</h3>

                    <p>With the facelift of our company website came its rebranding. I brainstormed with the other designers on the team on a new logo, color palette, and font choices. The full name of the company was "Commissions Inc.", which was difficult to create a logo for due to its long name. Marketing wanted something more modern and believed that condensing the name to "CINC" would be more memorable and catchy to potential clients. My fellow designer created a kick-ass logo and color palette. We also wanted to experiment with having a dark theme for the website to make the new colors pop. </p>

                    <br />
                    <br />
                    <div className="text-center">
                    <img src={cincLogo} width="70%" />
                    <p className="text-caption">
                        This was the final logo one of our designers came up with.
                    </p>
                    </div>
                    <br /><br />

                    <p>I collaborated with marketing on content, storytelling, images, and what they wanted to highlight on the website. With this, I created mockups in Illustrator and presented each new iteration to the designers and marketing for feedback. We went through several iterations, with each brainstorming session leading to a much better design.</p>

                    <p></p>

                </div>

                <img src={cincMockup1} width="100%" />
                

                <div className="container-short">
                <p className="text-caption">Early iterations of the website exploring visual approaches to slanted color blocks. Marketing eventually wanted something that showed screenshots of our product rather than using iconography.</p>
                <br />
                <br />
                    <h3>Building out the site</h3>

                    <p>With the recommendation of one of my fellow designers, we opted to use Webflow.com to create the HTML and CSS for the site to cut down on time. Marketing wanted to launch the site and brand redesign quickly, so there wasn’t much time to spend on building it out. The site was hosted in Wordpress so that marketing would be able to easily change content rather than ask one of our developers to push changes. Once the Webflow HTML/CSS was generated, I dissected it apart and concocted a new theme in Wordpress using PHP and page management plugins.</p>

                    <p>This was the fun part - I always love seeing designs come to life! We played around with parallax and adding subtle effects to the site, such as breaking up the flow with sections that scrolled horizontally like a slideshow. </p>

                    <p>I continuously gathered feedback from marketing as I was building the site out to see if they wanted to make any changes before the site launched. </p>

                </div>
                <img src={cincFinal} width="100%" />
                <div className="container-short">

                    <h3>Results</h3>

                    <p>The new rebrand and website design was received amazingly well from our internal employees and our potential clients. The conversion rate on the site skyrocketed, with more potential clients calling and signing up for a demo! </p>
                    <br /><br />
                    
                
                
                </div>

                <div className="container">
                <div className="text-left">
                    <a href="/case-study/georgia-aquarium">← Georgia Aquarium DiveOps Portal</a>
                </div>
                <br /><br />
                </div>
                

            </div>
        );
    }
}

export default cincWebsite;