import React, { Component } from 'react';
import CaseStudyTitle from '../Components/CaseStudyTitle';
import ParagraphBorderLeft from '../Components/ParagraphBorderLeft'
import caseStudy1 from '../Assets/img/housesnetapp/housenetmockup-screens.jpg';
import threadedMessaging1 from '../Assets/img/housesnetapp/messenger.jpg';
import threadedMessaging2 from '../Assets/img/housesnetapp/team-discussion.jpg';
import recommended from '../Assets/img/housesnetapp/Rec-liked.jpg';
import searchList from '../Assets/img/housesnetapp/search.jpg';
import searchMap from '../Assets/img/housesnetapp/map-1.jpg';
import searchMap2 from '../Assets/img/housesnetapp/map-2.jpg';
import searchFilters from '../Assets/img/housesnetapp/filters.jpg';
import searchLocation from '../Assets/img/housesnetapp/search-dropdown.jpg';
import propDetails from '../Assets/img/housesnetapp/prop-details-scheduled.jpg';
import propNotes from '../Assets/img/housesnetapp/notes.jpg';
import schedule from '../Assets/img/housesnetapp/schedule.jpg';
import { motion } from "framer-motion";


class housesNetApp extends Component {
    render() {
        return (
            <motion.div 
                initial={{ opacity: 0, y:-50 }}
                animate={{ y:0 }}
                whileInView={{ opacity: 1 }}
                transition={{duration:.75, ease: "easeOut" }}
            >
            <div class="case-study">
                <CaseStudyTitle title="houses.net mobile app" role="UX/UI Designer" />
                <div class="container">
                    <div class="case-study-hero img-shadow" style={{backgroundImage: "url(" + caseStudy1 + ")"}}>
                    </div>
                </div>
                
                <div className="container-short">
                    <ParagraphBorderLeft content="The houses.net was CINC's step into having a national home search app. We had already built the web version of it, so this was going to be the mobile counterpart that our clients would push to home buyers." />

                    <h3>The Challenge</h3>
                    <p>
                    The houses.net app was aimed towards connecting agents and home buyers in one place. At the time, we had a clunky consumer app named Local Homes that home buyers barely used. Houses.net would be the replacement for this and provide home buyers with a smoother app experience to search for homes. We also wanted to to have a national platform where leads could organically come in from the app store and connect with our agents.</p>

                    <p>Our product team wanted something that was more collaborative, involving a “search team” consisting of the buyer and their spouse along with the agent. This was something that wasn’t around during the time the idea was born. Most home search sites and apps had a page to store favorites and nothing else. We wanted to go beyond that and offer something called a “notebook”, where home buyers could store not only their favorites, but also share notes and messages between them, their spouse, even the agent they were working with. </p>

                    <h4>My Role</h4>

                    <p>I was the primary designer from 2015 to 2016, working with the mobile Product Manager as well as a team of Android and iOS Developers.</p>

                    <p>I participated in user research, usability testing, and prototyping. Eventually, another designer stepped in and took over the design of the app while the Agent Recommendations and Threaded Messaging pieces were still being ironed out.</p>

                    <div className="spacer"></div>

                    <h3>Talking to our users</h3>

                    <p>
                    We first met with home buyers to figure out if this was something that was actually needed in the market. My product manager and I grabbed random people from Starbucks for 10-20 minutes, asking them their pain points in searching and how they shared and collaborated their spouse (if they had one).</p>

                    <p>Most home buyers said that the collaboration piece was frustrating. They would send properties through emails, create shared spreadsheets to account for what they liked/disliked about the property, or just spoke to each other after work about the properties they liked. The whole process was just messy.</p>

                    <p>Collaborating with the agent could sometimes be difficult for them too. They would have to keep track of all the email and phone conversations about each property somewhere.</p>

                    <p>We also interviewed our clients for feedback on our idea. They expressed that they would love to give their clients personal attention and be able to push recommendations to home buyers with a push of a button through the app rather than trying to keep track of emails.</p>

                    <p>After gathering all this data, I set out to create wireframes and interactive mockups with Sketch and Invision to test our theories. Starbucks was once again our go-to for talking to home buyers, as well as the occasional interviews organized through Craigslist. 
                    </p>

                    <div className="spacer"></div>

                    <h3>Prototyping</h3>

                </div>

                <div className="spacer"></div>

                <div className="container">
                    <div className="row middle-xs">
                        <div className="col-sm-5">
                            <img className="img-shadow" src={searchList} width="100%" />
                        </div>
                        <div className="col-sm-7 p-large">
                            <p>For the search screen, we decided to show the listings first. Most home buyers preferred to see photos of the property before wanting to learn more information about them. Photos were definitely key, so they needed to be front and center.</p>

                            <p>On each property card was a like, dislike, and inquiry button. Choosing the thumbs up (like) would add it to their Likes folder, pressing the thumbs down (dislike) would remove it from their search (but still be accessible), and the inquiry button would allow them to craft a quick question to send to their agent.</p>

                            <p>Now the agent contact info up top was an interesting add on. My product manager insisted that our clients wanted their information shown so it feels more branded. I honestly disagreed with this idea, felt like we were focusing too much on what the client needed rather than the home buyer, and pushed against it. Ultimately we ran with it due to client expectation.</p>
                        </div>
                        
                    </div>
                </div>

                <div className="container-short">
                    <div className="spacer"></div>
                    <p><strong>2020 reflection:</strong> Looking back four years later, I would've conducted more user research to see if the agent contact section was something that our home buyers actually needed or used instead of us just tacking it on there to satisfy our clients.</p>

                    <p>The addition of three buttons to each property card was also a bit overkill. We eventually did cut out the inquiry button, since users showed us that they didn't notice it, didn't know what it did, and wouldn't use it.</p>

                    <div className="spacer"></div>

                </div>

                <div className="container">
                    <div className="row middle-xs">
                        <div className="col-sm-3">
                            <img className="img-shadow" src={searchMap} width="100%" />
                        </div>
                        <div className="col-sm-3">
                            <img className="img-shadow" src={searchMap2} width="100%" />
                        </div>
                        <div className="col-sm-3">
                            <img className="img-shadow" src={searchLocation} width="100%" />
                        </div>
                        <div className="col-sm-3">
                            <img className="img-shadow" src={searchFilters} width="100%" />
                        </div>
                    </div>
                </div>

                <div className="spacer"></div>
                <div className="container-short">

                    <p>Home buyers would be able to toggle the map view if they were more location-focused and wanted to see the neighborhood. </p>

                    <p>Some home buyers expressed that they wanted to search their current area in case they were driving around neighborhoods to look at home (which many did), so we added that to the address dropdown. Also included down below were their saved searches, in case they wanted to redo that search.</p>
                </div>

                <div className="spacer"></div>
                <div className="container">
                    <div className="row middle-xs">
                        <div className="col-sm-4">
                            <img className="img-shadow" src={propDetails} width="100%" />
                        </div>
                        <div className="col-sm-4">
                            <img className="img-shadow" src={propNotes} width="100%" />
                        </div>
                        <div className="col-sm-4">
                            <img className="img-shadow" src={schedule} width="100%" />
                        </div>
                    </div>
                </div>

                <div className="container-short">

                    <div className="spacer"></div>

                    <p>The property details page would show MLS information as well as new features like adding notes and requesting a showing. Many home buyers had a difficult time keeping track of what they liked and disliked about properties they were considering. They would create spreadsheets, look through email chains, or record their thoughts in a physical notebook. We wanted to help them keep their thoughts all in one place on the app.</p>

                    <p>The request showing feature would allow home buyers to easily connect with their agent to request an in-person private showing. </p>

                    <div className="spacer"></div>

                    <h3>Agent Recommendations</h3>

                    <p>One feature we came up with was a place where agents can push recommended properties to the home buyers based on their search history. Our CRM already allows the agent to look into a lead's saved search, favorites, and more. We believed that this would be useful for collaboration and allow home buyers to recieve properties hand-picked by the experts.</p>

                    <div className="row center-xs">
                        <div className="col-sm-6">
                            <img className="img-shadow" src={recommended} width="100%" />
                        </div>
                    </div>

                    <div className="spacer"></div>

                    <h3>Threaded Messaging</h3>

                    <p>In addition to the agent recommended properties, we also thought of having a space where the agent and home buyer can communicate through messages. This feature would work like text messages, but be housed in the app and show the history of the important actions, such as scheduling/canceling a showing and sharing a property.</p>

                    <div className="row">
                        <div className="col-sm-6">
                            <img className="img-shadow" src={threadedMessaging1} width="100%" />
                        </div>
                        <div className="col-sm-6">
                            <img className="img-shadow" src={threadedMessaging2} width="100%" />
                        </div>
                    </div>

                    <p>The home buyer could include their spouse into the conversation if they were added to the notebook.</p>
                    <p>We believed that this would further foster the agent-to-client relationship and allow home buyers to recieve personalized hospitality from their agent. Our agents were also thrilled by the idea.</p>

                    <div className="spacer"></div>

                    <h3>Results</h3>

                    <p>For the MVP, we released the app with everything but the threaded messaging project and agent recommendations, since that part of the app would take longer to go through user testing and research. The notebook had the ability to store favorited properties, write notes, and invite spouses into their notebook to collaborate. These changes were also reflected in our consumer web experience. </p>

                    <p>Several months later we introduced the agent recommendations.</p>

                    <p>Our clients loved the app and promoted it to their clients, the home buyers, who adopted the app well. We had thousands of downloads within the first three months. With our marketing team promoting the app on their channels, downloads continued to steadly increase.</p>
                        
                        <p>Threaded messaging was still in the discovery phase. Eventually, the app was turned over to our other designer, since I was needed to help with the consumer home-search redesign next. </p>

                        <br /><br />

                

                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="text-left">
                                <a href="/case-study/home-search">← Redesigning a home search experience</a>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="text-right">
                                <a href="/case-study/mass-message-wizard">A mass message wizard →</a>
                            </div>
                        </div>
                    </div>
                
                <br /><br />
                </div>
                

            </div>
            </motion.div>
        );
    }
}

export default housesNetApp;