import React, { Component } from 'react';

class ParagraphBorderLeft extends Component {
    render () {
        return (
            <p class="lead border-left">
                {this.props.content}
            </p>
        );
    }
}

export default ParagraphBorderLeft;